import React, { memo, VFC } from 'react'
import LibInfoList from '@sport1/news-styleguide/InfoList'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { GenericComponentProps, GenericTeamInfoModel as TeamInfoProps } from '@sport1/types/web'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'

type InfoListProps = Pick<
    TeamInfoProps & GenericComponentProps,
    'additionalData' | 'componentKey'
> & { testID?: string }

const TeamInfo: VFC<InfoListProps> = ({ additionalData, testID }) => {
    testID === undefined ? (testID = 'team-info') : testID
    return (
        <NonFlexingContainer marginBottom="spacing-11" testID={`${testID}-wrapper`}>
            <NonFlexingContainer
                width="100%"
                className="md:bg-white"
                padding={['spacing-6', 'spacing-6', 'spacing-9', 'spacing-11']}
                testID={`${testID}-inner-wrapper`}
            >
                <NonFlexingContainer marginBottom="spacing-6">
                    <CardHeadline
                        testID="team-info-headline"
                        cardHeadlineType={['cardHeadlineS', 'cardHeadlineM', 'cardHeadlineM']}
                        text="INFOS"
                    />
                </NonFlexingContainer>
                <NonFlexingContainer
                    padding={['spacing-6', 'spacing-6', 'spacing-6', 'spacing-none']}
                    backgroundColor="pearl"
                >
                    <LibInfoList items={additionalData} testID={`${testID}-list`} />
                </NonFlexingContainer>
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default memo(TeamInfo)
